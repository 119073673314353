export enum RoleType {
  ADMINISTRATOR = 'ADMINISTRATOR',
  DRIVER = 'DRIVER',
  RESPONSIBLE = 'RESPONSIBLE',
  PLAN = 'PLAN',
  INVOICE = 'INVOICE',
  REPORTS = 'REPORTS',
}

export interface RoleModel {
  id: number,
  name: string,
  de?: string,
  description?: string,
}

export const AllRoleTypes: string[] = [
  RoleType.ADMINISTRATOR,
  RoleType.DRIVER,
  RoleType.RESPONSIBLE,
  RoleType.PLAN,
  RoleType.INVOICE,
  RoleType.REPORTS,
]

export interface RoleRedirects {
  role: RoleType
  url: string
}


export const Redirects: RoleRedirects[] = [
  {role: RoleType.ADMINISTRATOR, url: '/admin-home'},
  {role: RoleType.PLAN, url: '/admin-home'},
  {role: RoleType.DRIVER, url: '/driver-rides'},
  {role: RoleType.RESPONSIBLE, url: '/driver-rides'},
  {role: RoleType.INVOICE, url: '/'},
  {role: RoleType.REPORTS, url: '/'},
]

export interface GroupModel {
  id: number,
  de: string,
}

export interface UserGroupModel {
  id: number
  de: string,
}

export interface GroupRoleModel {
  userId: number
  roleId: number
  groupId: number
  roleName: string
  roleTitle: string
  groupTitle: string
}
