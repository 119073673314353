import {Injectable} from '@angular/core'
import {logger} from 'nx/src/utils/logger'
import {convertToISODateString} from "../../../common/formatters/src/lib/format-local-date"

@Injectable({
  providedIn: 'root',
})
export class DateNavigationService {
  changeDay(selectedDate: Date, days: number): Date {
    logger.debug('DateNavigationService#changeDay: selectedDate=', selectedDate, ', days=', days)
    const adjustedDate = this.adjustDateByDays(selectedDate, days)
    logger.debug('DateNavigationService#changeDay: adjustedDate=', adjustedDate)
    const isoDate = convertToISODateString(adjustedDate)
    logger.debug('DateNavigationService#changeDay: isoDate=', isoDate)
    return adjustedDate
  }

  changeMonth(selectedDate: Date, months: number): Date {
    logger.debug('DateNavigationService#changeMonth: selectedDate=', selectedDate, ', days=', months)
    const adjustedDate = this.adjustDateByMonths(selectedDate, months)
    logger.debug('DateNavigationService#changeMonth: adjustedDate=', adjustedDate)
    const isoDate = convertToISODateString(adjustedDate)
    logger.debug('DateNavigationService#changeMonth: isoDate=', isoDate)
    return adjustedDate
  }

  toDay(): Date {
    const today = new Date()
    logger.debug('DateNavigationService#toDay: selectedDate=', today)
    return today
  }

  private adjustDateByDays(date: Date, days: number): Date {
    const newDate = new Date(date)
    newDate.setDate(date.getDate() + days)
    return newDate
  }

  private adjustDateByMonths(date: Date, months: number): Date {
    const newDate = new Date(date)
    newDate.setMonth(newDate.getMonth() + months) // Use setMonth to adjust months
    return newDate
  }
}
