import {Injectable} from '@angular/core'
import {UserModel, LoginRequestModel} from "@domain/user"
import {environment} from "@env"
import {MessageService} from "primeng/api"
import {logger} from "nx/src/utils/logger"
import {StorageService} from "@service/storage"
import {UserService} from "@service/user"
import {NavigationExtras, Router} from "@angular/router"
import {BehaviorSubject, Observable, of, tap} from "rxjs"
import { Redirects, RoleType } from '@domain/authorization'

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  // private userSubject = new BehaviorSubject<UserModel>(null)
  user!: UserModel
  roleStrings!: string[]
  // user = this.userSubject.asObservable()
  // isLoggedIn = new BehaviorSubject<boolean>(false)
  private isUserLoggedIn = new BehaviorSubject<boolean>(false)
  private userRoles = new BehaviorSubject<string[]>([])

  // store the URL so we can redirect after logging in
  redirectUrl: string | null = null

  constructor(
      private storageSVC: StorageService,
      private userSVC: UserService,
      private router: Router,
      private messageSVC: MessageService,
  ) {
  }

  /*
   login(login: string, password: string): Observable<boolean> {
   const url = `${environment.apiUrl}/login`
   const body = {
   login: login,
   password: password,
   }

   this.http.post<UserModel>(url, body).pipe(
   map(user => {
   console.debug('user=', user)
   this.isLoggedIn = true
   localStorage.setItem('user', JSON.stringify(user))
   }),
   )

   return of(this.isLoggedIn)
   }
   */

  login(request: LoginRequestModel): Observable<UserModel> {
    logger.debug('authService#login: login=', request.login)
    if (this.storageSVC.isLoggedIn(request.login)) {
      logger.debug('authService#login: loggedIn=true, user=', this.user)
      return of(this.user)
    }
    return this.userSVC.login(request)
  }

  logout() {
    logger.debug('authService#logout: user=', this.user)
    const url = `${environment.apiUrl}/logout`
    this.storageSVC.removeLoginUser(this.user)
    this.userSVC.logout(this.user.id).subscribe({
      next: (value) => {
        this.isUserLoggedIn.next(false)
      },
      complete: () => {
        logger.debug(`auth-service#logout: ok`)
      },
    })
    // this.userSubject.next(null)
  }

  get isLoggedIn() {
    logger.debug('authService#isLoggedIn')
    this.user = this.getLoginUser()
    logger.debug('authService#isLoggedIn: user=', this.user)
    if (this.user) {
      if (this.storageSVC.isLoggedIn(this.user.login)) {
        this.isUserLoggedIn.next(true)
      } else {
        this.isUserLoggedIn.next(false)
      }
    }
    return this.isUserLoggedIn.asObservable()
  }

  get roles() {
    logger.debug('authService#roles')
    this.user = this.getLoginUser()
    logger.debug('authService#roles: user=', this.user)
    if (this.user) {
      this.userRoles.next(this.user.roles)
    }
    return this.userRoles.asObservable()
  }

  saveLoginUser(user: UserModel) {
    this.storageSVC.saveLoginUser(user)
  }

  getLoginUser(): UserModel {
    return this.storageSVC.getLoginUser()
  }

  getLoginRoles():string[] {
    this.roleStrings = this.storageSVC.getLoginRoles()
    return this.roleStrings
  }

  hasOneOfRoles(roles: RoleType[]): boolean {
    return this.storageSVC.hasOneOfRoles(roles)
  }

  redirectUser() {
    const navigationExtras: NavigationExtras = {
      queryParamsHandling: 'preserve',
      preserveFragment: true,
    }
    const url = this.getRedirectUrl()
    logger.debug('auth-service#redirectUser: url=', url, ', navigationExtras=', navigationExtras)
    const result=this.router.navigate([url], navigationExtras)
    logger.debug('auth-service#redirectUser: result=', result)
  }

  private hasRole(role: RoleType): boolean {
    return this.roleStrings.includes(role.toString())
  }

  /**
   * Gets the redirect URL based on the user's roles. Highest permissions
   * come first (s. Redirects constant)
   * @private
   * @returns {string} The redirect URL.
   */
  private getRedirectUrl() {
    let url = '/login'
    this.getLoginRoles()

    for (let roleUrl of Redirects) {
      if (this.hasRole(roleUrl.role)) {
        url = roleUrl.url
        break
      }
    }
    logger.debug('auth-service#getRedirectUrl: url=', url)
    return url
  }

  /*
   getToken() {
   return 'some-tokenl'
   }
   */
}
