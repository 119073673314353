import { environment } from '@env'
import { Injectable, NgZone } from '@angular/core'
import { map, Observable } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class ServerSentEventService {

  constructor(private zone: NgZone) {}

  /*
   getByEventId(eventId: number): Observable<JournalModel[]> {
   const url = `${environment.apiUrl}/journal/events/${eventId}`
   return this.http.get<JournalModel[]>(url).pipe(
   map(value => {
   return value
   }),
   )
   }
   */

  getServerSentEvent(context: string): Observable<any> {
    return new Observable(observer => {
      const url = `${environment.apiUrl}/sse/${context}`
      const eventSource = new EventSource(url)

      eventSource.onmessage = (event) => {
        this.zone.run(() => {
          observer.next(event)
        })
      }

      eventSource.onerror = (error) => {
        this.zone.run(() => {
          observer.error(error)
        })
      }

      return () => eventSource.close()
    })
  }
}
